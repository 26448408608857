





























































import { OrganizationInputDto } from '@portals/shared/admin/OrganizationDto';
import Vue from 'vue';

import AdminButtonGroup from '@/components/AdminButtonGroup.vue';
import AdminPopUp from '@/components/AdminPopUp.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DnbButton from '@/components/DnbButton.vue';
import DnbInput from '@/components/DnbInput.vue';
import Loader from '@/components/Loader.vue';
import {
  deleteOrganization as deleteOrganizationService,
  fetchOrganization,
  saveOrganization,
} from '@/service/organizationService';
import { scrollToError } from '@/utils';

import { Breadcrumb } from '../../models/Breadcrumb';

type Data = {
  loaded: boolean;
  create: boolean;
  organization: OrganizationInputDto;
  errors: Record<string, unknown>;
  errorMessage: string | undefined;
  displayDeleteOrganizationPopUp: boolean;
  updating: boolean;
};

export default Vue.extend({
  name: 'edit-organization',
  components: {
    DnbInput,
    Breadcrumbs,
    DnbButton,
    AdminPopUp,
    AdminButtonGroup,
    Loader,
  },
  props: {},
  data(): Data {
    return {
      loaded: false,
      create: !this.$route.params.id,
      organization: { name: '', domain: '' },
      errors: {},
      errorMessage: undefined,
      displayDeleteOrganizationPopUp: false,
      updating: false,
    };
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      const crumbs: Breadcrumb[] = [
        {
          name: 'Organizations',
          path: '/organizations',
        },
      ];
      if (this.organization) {
        crumbs.push(
          {
            name: this.organization.name,
            path: `/organizations/${this.organization.id}`,
          },
          {
            name: 'Edit Organization',
          },
        );
      } else {
        crumbs.push({
          name: 'Create Organization',
        });
      }
      return crumbs;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.loadOrganization(this.$route.params.id);
    } else {
      this.loaded = true;
    }
  },
  methods: {
    async loadOrganization(id: string) {
      this.loaded = false;
      this.organization = await fetchOrganization(id);
      this.loaded = true;
    },

    async save() {
      this.updating = true;
      this.errorMessage = undefined;

      try {
        await saveOrganization(this.organization);
        this.updating = false;
        this.$router.push('/organizations');
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data.code === 'ValidationError'
        ) {
          this.errors = error.response.data.errors;
          Vue.nextTick(scrollToError);
        } else if (error && error.response) {
          this.errorMessage = error.response.data.message;
        }
      } finally {
        this.updating = false;
      }
    },
    async deleteOrganization() {
      try {
        if (this.organization.id) {
          this.updating = true;
          await deleteOrganizationService(this.organization.id);
          this.$router.push('/organizations');
          this.updating = false;
        }
      } catch (error) {
        if (error && error.response) {
          this.errorMessage = error.response.data.message;
          this.updating = false;
        }
      } finally {
        this.displayDeleteOrganizationPopUp = false;
        this.updating = false;
      }
    },
    toggleDeletePopUp() {
      this.displayDeleteOrganizationPopUp =
        !this.displayDeleteOrganizationPopUp;
    },
  },
});
